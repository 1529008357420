.navBarLogo {
    width: 120px;

    @media (max-width: 677px) {
        width: 90px;
    }
}

.navbar-transparent2 {
    background-color: rgba(255, 255, 255, 1);
}

.navbar-toggler-bar {
    background: linear-gradient(119.73deg, rgba(155, 235, 255, 0.8) 45.01%, rgba(67, 173, 254, 0.8) 78.49%), #4A59FF !important;
}

.font-CN-Bold {
    font-family: "NotoSansSC-Bold";
}

.font-CN-Black {
    font-family: "NotoSansSC-Black";
}

.font-CN-Medium {
    font-family: "NotoSansSC-Medium";
}

.font-CN-Regular {
    font-family: "NotoSansSC-Regular";
}

.font-CN-Light {
    font-family: "NotoSansSC-Light";
}

.font-CN-Thin {
    font-family: "NotoSansSC-Thin";
}

.nav-item {
    display: flex;
    align-items: center;
}

.sidebar-collapse .navbar .dropdown.show .dropdown-menu,
.sidebar-collapse .navbar .dropdown .dropdown-menu {

    @media (max-width: 991px) {
        // margin: 0 !important;
    }
}

.navItemText {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #767676;
    text-transform: capitalize;

    @media (max-width: 991px) {
        color: white;
    }
}

.navbar-icon {
    color: #459DFF;

    @media (max-width: 991px) {
        color: white;
    }
}

.navbar-icon2 {
    color: #459DFF;
    display: none;

    @media (max-width: 991px) {
        display: none;
    }
}

.dropdown-toggle::after {
    color: #459DFF;
    font-size: 18px;
    margin-left: 8px;

    @media (max-width: 991px) {
        color: #fff;
    }
}


.learnMoreBtn {
    background: linear-gradient(119.73deg, rgba(155, 235, 255, 0.8) 45.01%, rgba(67, 173, 254, 0.8) 78.49%), #4A59FF;
    border-radius: 20px;

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: #767676;
    }
}

.navbar-nav .nav-item:not(:last-child) {
    @media screen and (min-width: 991px) {
        margin-right: 30px;
    }
}

.headerSection {
    padding-top: 20vh;
    padding-bottom: 10vh;
}

.headerTitle {
    font-weight: 500;
    font-size: 40px;
    line-height: 160%;
    text-align: center;
    background: linear-gradient(119.73deg, rgba(155, 235, 255, 0.8) 45.01%, rgba(67, 173, 254, 0.8) 78.49%), #4A59FF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media (max-width: 991px) {
        font-size: 28px;
        line-height: 160%;
    }

    @media (max-width: 677px) {
        font-size: 20px;
        line-height: 160%;
    }
}

.headerSubTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #767676;

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 23px;
    }

    @media (max-width: 677px) {
        font-size: 13px;
        line-height: 20px;
    }
}

.downloadAppImg {
    width: 180px;

    @media (max-width: 677px) {
        width: 120px;
    }
}

.bar1 {
    background: #0018DE;
    transform: rotate(1.02deg);
    width: 100%;
    height: 7vh;
}

.bar2 {
    background: linear-gradient(112.49deg, #9BEBFF -15.2%, #43ADFE 101.56%);
    transform: rotate(-3.13deg);
    width: 100%;
    height: 7vh;
    position: absolute;
    top: 0;
    left: 0;
}

.w-20 {
    width: 20%;
}

.sectionHeaderText {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #767676;

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 23px;
    }

    @media (max-width: 677px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.sectionTitleText {
    font-weight: 500;
    font-size: 30px;
    line-height: 43px;
    color: #459DFF;

    @media (max-width: 991px) {
        font-size: 28px;
        line-height: 160%;
    }

    @media (max-width: 677px) {
        font-size: 20px;
        line-height: 160%;
    }
}

.sectionDescText {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #767676;

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 23px;
    }

    @media (max-width: 677px) {
        font-size: 13px;
        line-height: 20px;
    }
}

.visionSlideBg {
    padding: 20px;
}

.inactiveVisionSlide {
    .visionSlideBg {
        filter: brightness(30%);
        padding-bottom: 10vh;
        padding-top: 10vh;
    }

    &:hover,
    &:focus {
        .visionSlideBg {
            filter: brightness(80%);
        }

        transform: translateY(-3em);
    }
}


.visionSlideIconSize {
    width: 40%;
}

.visionSlideContentPos {
    position: absolute;
    top: 28%;
    left: 15%;
    text-align: left;
    width: 70%;
}

.visionSlideText {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #F0FAFF;
    margin-left: 5%;
}

.visionSlideLine {
    border: 1px solid #459DFF;
    width: 100%;
    margin-left: 0%;
}

.missionImgSize {
    width: 90%;

    @media (max-width: 991px) {
        width: 100%;
    }
}

.activeMobileSlide {
    img {
        transform: scale(1.4);
    }

    padding-top: 5vh;
    padding-bottom: 5vh;
}

.inactiveMobileSlide {
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.footer {
    border-top: 3px solid #001BE0;
}


.footerLogoSize {
    width: 180px;

    @media (max-width: 667px) {
        width: 150px;
    }
}

.copywriteText {
    // font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #767676;
}

.footer-textBox {
    border: 1px solid #D9D9D9;
    border-radius: 10px 0 0 10px;
    height: 50px;
}

.subscribeBtn {
    background: #FDB515;
    border-radius: 0px 10px 10px 0px;
    width: 200px;
    height: 50px;
}


#cards {
    padding-bottom: calc(var(--numcards) * var(--card-top-offset));
    /* Make place at bottom, as items will slide to that position*/
    margin-bottom: var(--card-margin);
    /* Don't include the --card-margin in padding, as that will affect the scroll-timeline*/
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.card1TextPos {
    position: absolute;
    top: 0;
}

.card1Bg {
    background: linear-gradient(90deg, #FDA37F 27.75%, rgba(253, 163, 127, 0.514541) 48.38%, rgba(253, 163, 127, 0) 57.75%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 70px;

    @media (max-width: 667px) {
        border-radius: 30px;
    }
}

.card2Bg {
    background: linear-gradient(90deg, #4B47F4 19.91%, rgba(75, 71, 244, 0.67) 41.4%, rgba(75, 71, 244, 0) 57.75%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 70px;

    @media (max-width: 667px) {
        border-radius: 30px;
    }
}


.card3Bg {
    background: linear-gradient(90deg, #B82262 23.02%, rgba(184, 34, 98, 0.514541) 45.35%, rgba(184, 34, 98, 0) 67.89%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 70px;

    @media (max-width: 667px) {
        border-radius: 30px;
    }
}

.card4Bg {
    background: linear-gradient(90.23deg, #8643FA 26.08%, rgba(134, 67, 250, 0.514541) 43.75%, rgba(134, 67, 250, 0) 60.84%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 70px;

    @media (max-width: 667px) {
        border-radius: 30px;
    }
}

#card_1 {
    --index: 1;
}

#card_2 {
    --index: 2;
}

#card_3 {
    --index: 3;
}

#card_4 {
    --index: 4;
}

.card {
    position: sticky;
    top: 20%;
    border-radius: 70px;
    background-color: red;
    padding-top: calc(var(--index) * var(--card-top-offset));

    @media (max-width: 667px) {
        border-radius: 30px;
    }
}

@supports (animation-timeline: works) {

    @scroll-timeline cards-element-scrolls-in-body {
        source: selector(body);
        scroll-offsets:
            /* Start when the start edge touches the top of the scrollport */
            selector(#cards) start 1,
            /* End when the start edge touches the start of the scrollport */
            selector(#cards) start 0;
        start: selector(#cards) start 1;
        /* Start when the start edge touches the top of the scrollport */
        end: selector(#cards) start 0;
        /* End when the start edge touches the start of the scrollport */
        time-range: 4s;
    }

    .card {
        --index0: calc(var(--index) - 1);
        /* 0-based index */
        --reverse-index: calc(var(--numcards) - var(--index0));
        /* reverse index */
        --reverse-index0: calc(var(--reverse-index) - 1);
        /* 0-based reverse index */
    }

    .card__content {
        transform-origin: 50% 0%;
        will-change: transform;

        --duration: calc(var(--reverse-index0) * 1s);
        --delay: calc(var(--index0) * 1s);

        animation: var(--duration) linear scale var(--delay) forwards;
        animation-timeline: cards-element-scrolls-in-body;
    }

    @keyframes scale {
        to {
            transform:
                scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
        }
    }
}

#cards {
    list-style: none;
    outline: calc(var(--outline-width) * 10) solid blue;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(var(--numcards), var(--card-height));
    gap: var(--card-margin);
}

.card {
    outline: var(--outline-width) solid hotpink;
}

.card__content {
    box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0), 0 1em 2em rgba(0, 0, 0, 0);
    background: rgb(255, 255, 255);
    color: rgb(10, 5, 7);
    border-radius: 70px;
    overflow: hidden;

    // display: grid;
    grid-template-areas: "text img";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    align-items: stretch;
    outline: var(--outline-width) solid lime;

    @media (max-width: 667px) {
        border-radius: 30px;
    }
}

.card__content>div {
    grid-area: text;
    width: 80%;
    place-self: center;
    text-align: left;

    display: grid;
    gap: 1em;
    place-items: start;
}

.card__content>div {
    grid-area: img;
    overflow: hidden;
}

.card__content {
    video {
        width: 100%;
        height: 450px !important;
        object-fit: cover;

        @media (max-width: 667px) {
            height: 230px !important;
        }
    }
}

.cardTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;

    @media (max-width: 991px) {
        font-size: 30px;
        line-height: 40px;
    }

    @media (max-width: 667px) {
        font-size: 16px;
        line-height: 25px;
    }
}

.floating {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

.floating2 {
    animation-name: floating2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}


@keyframes floating {
    0% {
        transform: translate(0px, 20px);
    }

    50% {
        transform: translate(0px, -20px);
    }

    100% {
        transform: translate(0px, 20px);
    }
}

@keyframes floating2 {
    0% {
        transform: translate(0px, -30px);
    }

    50% {
        transform: translate(0px, 30px);
    }

    100% {
        transform: translate(0px, -30px);
    }
}

.stickyParticles {
    position: sticky;
    z-index: 1;
}

.particlePos1 {
    position: absolute;
    top: 10%;
    left: 15%;
}

.particlePos2 {
    position: absolute;
    top: 10%;
    left: 0%;
}

.particlePos3 {
    position: absolute;
    top: 18%;
    left: 20%;

    @media (max-width: 991px) {
        display: block;
    }

    @media (max-width: 667px) {
        display: none;
    }
}

.particlePos4 {
    position: absolute;
    top: 32%;
    left: 20%;

    @media (max-width: 991px) {}

    @media (max-width: 667px) {
        display: none;
    }
}

.particlePos5 {
    position: absolute;
    top: 50%;
    left: 10%;
}

.particlePos6 {
    position: absolute;
    top: 18%;
    right: 10%;
}

.particlePos7 {
    position: absolute;
    top: 18%;
    right: 0%;
}

.particlePos8 {
    position: absolute;
    top: 40%;
    right: 18%;
}

.particlePos9 {
    position: absolute;
    top: 60%;
    right: 7%;
}

.particlePos10 {
    position: absolute;
    top: 58%;
    right: 22%;
}

.particlePos11 {
    position: absolute;
    top: 5%;
    left: 43%;

}

.particlePos12 {
    position: absolute;
    top: -10%;
    right: 5%;

    @media (max-width: 991px) {
        display: block;
    }

    @media (max-width: 667px) {
        display: none;
    }
}

.particlePos13 {
    position: absolute;
    top: 54%;
    right: 38%;
}

.particlePos14 {
    position: absolute;
    bottom: 0%;
    right: 10%;
}

.particlePos15 {
    position: absolute;
    top: 0%;
    left: 0%;
}

.particlePos16 {
    position: absolute;
    top: -10%;
    left: 0%;
}

.particlePos17 {
    position: absolute;
    top: 10%;
    left: 50%;
}

.particlePos18 {
    position: absolute;
    bottom: 10%;
    left: 10%;

}

.particlePos19 {
    position: absolute;
    bottom: -15%;
    right: 0%;
}

.particlePos20 {
    position: absolute;
    bottom: 10%;
    right: 10%;
}

.particlePos21 {
    position: absolute;
    bottom: 10%;
    left: 10%;
}

.particlePos22 {
    position: absolute;
    bottom: 10%;
    right: 10%;
}

.particlePos23 {
    position: absolute;
    bottom: -10%;
    left: 10%;
}

@media screen and (max-width: 300px) {
    .sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
        width: 200px !important;
    }

    .nav-open .sidebar-collapse .navbar-translate {
        transform: translate3d(-200px, 0, 0);
    }

    .sidebar-collapse #bodyClick {
        right: 200px;
    }
}

.dropdown-menu-right {
    @media (min-width: 991px) {
        box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.17);
        background-color: white !important;
    }
}

.dropdown-item {
    @media (min-width: 991px) {
        box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.17);
        background-color: white !important;
        color: black !important;
    }
}

.navbar-nav .nav-item .nav-link:not(.btn):hover {
    @media (max-width: 991px) {
        background-color: transparent !important;
    }
}

.sidebar-collapse .navbar-collapse:before {
    background: linear-gradient(#2CA8FF, #fff 90%) !important;
}